import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VariableService } from '../../services';
import { SNACKBAR } from '../../constants';
import { useStore } from '../../hooks';
import {
  CustomButton, CustomPaper, CustomTextField, CustomTypography,
} from '../../components';
import { createUserVariableValidationSchema } from './rules';

const DEFAULT_VALUES = {
  user: '',
  conversation: '',
  name: '',
  value: '',
};

type FormInputsTypes = {
  user: string,
  conversation: string,
  name: string,
  value: string,
};

export const CreateUserVariablesPage: React.FC = () => {
  const { setSnackBar } = useStore();
  const { handleSubmit, control, reset } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(createUserVariableValidationSchema),
  });

  const handleCreate = async (data: FormInputsTypes): Promise<void> => {
    try {
      await VariableService.createUserVariables(data);
      reset();
      setSnackBar({ severity: SNACKBAR.SEVERITY.SUCCESS, message: 'User variable was successfully created' });
    } catch (err) {
      setSnackBar({ severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message });
    }
  };

  return (
    <CustomPaper>
      <CustomTypography text="Create user variable" />
      <CustomTextField name="user" control={control} label="User" />
      <CustomTextField name="conversation" control={control} label="Conversation" />
      <CustomTextField name="name" control={control} label="Name" />
      <CustomTextField name="value" control={control} label="Value" />
      <CustomButton onClick={handleSubmit(handleCreate)} title="Create" />
    </CustomPaper>
  );
};
