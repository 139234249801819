import { AxiosResponse } from 'axios';
import { api } from '../http';
import { ROUTES } from '../constants';
import {
  CreateUserVariablesRequest, CreateUserVariablesResponse,
  DeleteVariableParams, DeleteVariablesResponse, UpdateVariableParams,
  UpdateVariableRequest, UpdateVariablesResponse,
} from '../types';

export class VariableService {
  static async createUserVariables(data: CreateUserVariablesRequest):
      Promise<AxiosResponse<CreateUserVariablesResponse>> {
    return api.post<CreateUserVariablesResponse>(ROUTES.VARIABLES, data);
  }

  static async fetchUserVariables(user: string): Promise<AxiosResponse<[]>> {
    return api.get(`${ROUTES.VARIABLES}/${user}`);
  }

  static async updateVariable(params: UpdateVariableParams, data: UpdateVariableRequest):
      Promise<AxiosResponse<UpdateVariablesResponse>> {
    return api.put(`${ROUTES.VARIABLES}/${params.user}/${params.conversation}/${params.name}`, data);
  }

  static async deleteVariable(params: DeleteVariableParams):
      Promise<AxiosResponse<DeleteVariablesResponse>> {
    return api.delete(`${ROUTES.VARIABLES}/${params.user}/${params.conversation}/${params.name}`);
  }
}
