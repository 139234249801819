import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '../../hooks';
import {
  CustomButton, CustomPaper, CustomTextField, CustomTypography,
} from '../../components';
import { validationSchema } from './rules';
import { ROUTES } from '../../constants';
import { VerifyEmailRequest } from '../../types';

const DEFAULT_VALUES = {
  email: '',
  verificationCode: '',
};

type FormInputsTypes = {
  email: string;
  verificationCode: string;
};

export const VerifyEmailPage: React.FC = () => {
  const { authStore: { verifyEmail } } = useStore();
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const handleVerification = useCallback(async (data: VerifyEmailRequest): Promise<void> => {
    const isSuccessful = await verifyEmail(data.email, data.verificationCode);
    if (isSuccessful) {
      reset();
      navigate(ROUTES.AUTH.LOGIN);
    }
  }, []);

  return (
    <CustomPaper>
      <CustomTypography text="Email Verification" />
      <CustomTextField name="email" control={control} label="Email" />
      <CustomTextField name="verificationCode" control={control} label="Verification Code" />
      <CustomButton onClick={handleSubmit(handleVerification)} title="Verify" />
    </CustomPaper>
  );
};
