import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import {
  CustomButton, CustomPaper, CustomTextField, CustomTypography,
} from '../../../components';
import { ROUTES } from '../../../constants';
import { validationSchema } from './rules';

const DEFAULT_VALUES = {
  email: '',
  verificationCode: '',
  password: '',
  passwordConfirmation: '',
};

type FormInputsTypes = {
  email: string;
  verificationCode: string;
  password: string;
  passwordConfirmation: string;
};

export const UpdatePasswordPage: React.FC = observer(() => {
  const { authStore: { updatePassword } } = useStore();
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const handleUpdatePassword = useCallback(async (data: FormInputsTypes): Promise<void> => {
    const isSuccessful = await updatePassword(data);
    if (isSuccessful) {
      reset();
      navigate(ROUTES.AUTH.LOGIN);
    }
  }, []);
  return (
    <CustomPaper>
      <CustomTypography text="Password Updating (step 2 of 2)" />
      <CustomTextField name="email" control={control} label="Email" />
      <CustomTextField name="verificationCode" control={control} label="Verification Code" />
      <CustomTextField name="password" control={control} label="Enter new password" type="password" />
      <CustomTextField name="passwordConfirmation" control={control} label="Confirm new password" type="password" />
      <CustomButton onClick={handleSubmit(handleUpdatePassword)} title="Update" />
    </CustomPaper>
  );
});
