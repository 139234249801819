import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { NavLink, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import {
  CustomButton, CustomPaper, CustomTextField, CustomTypography,
} from '../../components';
import { LoginRequest } from '../../types';
import { ROUTES } from '../../constants';
import { useStore, useStyles } from '../../hooks';
import { validationSchema } from './rules';

const DEFAULT_VALUES = {
  email: '',
  password: '',
};

type FormInputsTypes = {
  email: string;
  password: string;
};

export const LoginPage: React.FC = () => {
  const styles = useStyles();
  const { authStore: { login } } = useStore();
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const handleLogin = useCallback(async (data: LoginRequest): Promise<void> => {
    const isSuccessful = await login(data.email, data.password);
    if (isSuccessful) {
      reset();
      navigate(ROUTES.ROOT);
    }
  }, []);

  return (
    <CustomPaper>
      <CustomTypography text="Login form" />
      <CustomTextField name="email" control={control} label="Email" />
      <CustomTextField name="password" control={control} label="Password" type="password" />
      <CustomButton onClick={handleSubmit(handleLogin)} title="Login" />
      <Box textAlign="center">
        <NavLink to={ROUTES.AUTH.VERIFY_EMAIL} className={styles.noDecoration}>
          <Button size="large" style={{ marginRight: '2rem' }}>Verify email</Button>
        </NavLink>
        <NavLink to={ROUTES.AUTH.RECOVER_PASSWORD} className={styles.noDecoration}>
          <Button size="large">Recover password</Button>
        </NavLink>
      </Box>
    </CustomPaper>
  );
};
