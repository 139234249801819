import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { DefaultVariableService } from '../../services';
import { SNACKBAR } from '../../constants';
import { useStore } from '../../hooks';
import {
  CustomButton, CustomDialog, CustomPaper, CustomTextField, CustomTypography,
} from '../../components';
import { createDefaultVariableValidationSchema } from './rules';
import { API_STATUS } from '../../constants/api-status';

const DEFAULT_VALUES = {
  conversation: '',
  name: '',
  value: '',
};

type FormInputsTypes = {
  conversation: string,
  name: string,
  value: string,
};

export const CreateDefaultVariablesPage: React.FC = () => {
  const [variable, setVariable] = useState<FormInputsTypes>(DEFAULT_VALUES);
  const [warning, setWarning] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { setSnackBar } = useStore();
  const { handleSubmit, control, reset } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(createDefaultVariableValidationSchema),
  });

  const handleCreate = async (data: FormInputsTypes): Promise<void> => {
    try {
      await DefaultVariableService.createDefaultVariables(data);
      reset();
      setSnackBar({ severity: SNACKBAR.SEVERITY.SUCCESS, message: 'Default variable was successfully created' });
    } catch (err) {
      if (err.response.status === API_STATUS.CONFLICT) {
        setSnackBar({ severity: SNACKBAR.SEVERITY.INFO, message: err.response.data.info });
        setWarning(err.response.data.info);
        setVariable(data);
        setIsOpen(true);
      } else {
        setSnackBar({ severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message });
      }
    }
  };

  const handleUpdate = async (): Promise<void> => {
    setIsOpen(false);
    try {
      // eslint-disable-next-line max-len
      await DefaultVariableService.updateDefaultVariables({ conversation: variable.conversation, name: variable.name }, { value: variable.value });
      reset();
      setSnackBar({ severity: SNACKBAR.SEVERITY.SUCCESS, message: 'Default variable was successfully updated' });
    } catch (err) {
      setSnackBar({ severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message });
    }
  };

  return (
    <CustomPaper>
      <CustomTypography text="Create default variable" />
      <CustomTextField name="conversation" control={control} label="Conversation" />
      <CustomTextField name="name" control={control} label="Name" />
      <CustomTextField name="value" control={control} label="Value" />
      <CustomButton onClick={handleSubmit(handleCreate)} title="Create" />
      <CustomDialog
        isOpen={isOpen}
        onClose={setIsOpen}
        handleConfirm={handleUpdate}
        dialogText={warning}
      />
    </CustomPaper>

  );
};
