import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
    isOpen: boolean
    onClose: (value: boolean) => void
    handleConfirm: () => void
    dialogText: string
}

export const CustomDialog: React.FC<Props> = ({
  isOpen, onClose, handleConfirm, dialogText,
}) => {
  const handleClose = (): void => {
    onClose(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ marginBottom: '16.5rem' }}
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to update the variable?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>No</Button>
          <Button onClick={handleConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
