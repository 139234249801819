import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { useStore, useStyles } from '../../../hooks';
import {
  CustomButton, CustomPaper, CustomTextField, CustomTypography,
} from '../../../components';
import { validationSchema } from './rules';
import { ROUTES } from '../../../constants';
import { SendRecoveryCodeRequest } from '../../../types';

const DEFAULT_VALUES = {
  email: '',
};

type FormInputsTypes = {
  email: string;
};

export const RecoverPasswordPage: React.FC = observer(() => {
  const styles = useStyles();
  const { authStore: { sendRecoveryCode }, isSendingEmail } = useStore();
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const handleSendCode = useCallback(async (data: SendRecoveryCodeRequest): Promise<void> => {
    const isSuccessful = await sendRecoveryCode(data.email);
    if (isSuccessful) {
      reset();
      navigate(ROUTES.AUTH.UPDATE_PASSWORD);
    }
  }, []);
  return (
    <CustomPaper>
      <CustomTypography text="Password Updating (step 1 of 2)" />
      <CustomTextField name="email" control={control} label="Enter your email and we will send you the password recovery code" />
      {isSendingEmail ? <h4 style={{ marginTop: '1rem' }}>Sending code...</h4> : (
        <>
          <CustomButton onClick={handleSubmit(handleSendCode)} title="Send code" />
          <Box textAlign="center">
            <NavLink to={ROUTES.AUTH.UPDATE_PASSWORD} className={styles.noDecoration}>
              <Button size="large" className={styles.noTransform}>Already have the code?</Button>
            </NavLink>
          </Box>
        </>
      )}
    </CustomPaper>
  );
});
