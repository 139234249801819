import * as React from 'react';
import { styled } from '@mui/system';
import { ROUTES } from '../../constants';
import { CustomNavLink } from '../../components';

const Div = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10rem',
});

export const VariablesPage: React.FC = () => (
  <Div>
    <CustomNavLink to={ROUTES.CREATE_USER_VARIABLES} title="Create User Variable" color="primary" />
    <CustomNavLink to={ROUTES.UPDATE_USER_VARIABLES} title="Update User Variable" color="primary" />
  </Div>
);
