import { makeAutoObservable } from 'mobx';
import { RootStore } from '../RootStore';
import {
  DefaultVariable,
  DeleteDefaultVariableParams,
  UpdateDefaultVariablesParams,
  UpdateVariableRequest,
} from '../../types';
import { DefaultVariableService } from '../../services';
import { SNACKBAR } from '../../constants';

export class DefaultVariableStore {
  defaultVariables: Array<DefaultVariable> = [];

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setDefaultVariables = (variables: Array<DefaultVariable>): void => {
    this.defaultVariables = variables;
  };

  getDefaultVariables = async (conversation: string): Promise<void> => {
    try {
      const response = await DefaultVariableService.fetchDefaultVariables(conversation);
      if (!response.data.length) {
        this.rootStore.setSnackBar({ severity: SNACKBAR.SEVERITY.ERROR, message: `There are no default variables for: '${conversation}' conversation` });
      }
      this.setDefaultVariables(response.data);
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
  };

  deleteDefaultVariable = async (params: DeleteDefaultVariableParams): Promise<boolean> => {
    try {
      await DefaultVariableService.deleteDefaultVariable(params);
      await this.getDefaultVariables(params.conversation);
      this.rootStore.setSnackBar({ message: 'Default variable was successfully deleted' });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
      return false;
    }
  };

  updateDefaultVariable = async (params: UpdateDefaultVariablesParams, data: UpdateVariableRequest):
      Promise<boolean> => {
    try {
      await DefaultVariableService.updateDefaultVariables(params, data);
      await this.getDefaultVariables(params.conversation);
      this.rootStore.setSnackBar({ message: 'Default variable was successfully updated' });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
      return false;
    }
  };
}
