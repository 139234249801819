type SnackbarType = {
  OPEN: boolean;
  SEVERITY: {
    ERROR: 'error';
    SUCCESS: 'success';
    INFO: 'info';
  };
  HIDE_DURATION: number;
  ELEVATION: number;
  VARIANT: 'filled';
  CLICKAWAY: string;
};

export const SNACKBAR: SnackbarType = {
  OPEN: true,
  SEVERITY: {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
  },
  HIDE_DURATION: 6000,
  ELEVATION: 6,
  VARIANT: 'filled',
  CLICKAWAY: 'clickaway',
};
