import React from 'react';
import { GlobalStyles } from '@mui/system';
import { COLORS } from '../../constants';

const GLOBAL_STYLES = {
  '*': { margin: 0, padding: 0 },
  body: { backgroundColor: COLORS.BACKGROUND },
};

export const GlobalStyle: React.FC = () => (
  <GlobalStyles
    styles={GLOBAL_STYLES}
  />
);
