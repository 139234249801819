import * as Yup from 'yup';
import {
  emailConfirmationValidation,
  emailValidation,
  passwordConfirmationValidation,
  passwordValidation,
} from '../../helpers';

export const validationSchema = Yup.object().shape({
  email: emailValidation(),
  emailConfirmation: emailConfirmationValidation(),
  password: passwordValidation(),
  passwordConfirmation: passwordConfirmationValidation(),
});
