import { AxiosResponse } from 'axios';
import { api } from '../http';
import { ROUTES } from '../constants';
import {
  CreateDefaultVariablesRequest,
  CreateDefaultVariablesResponse,
  DeleteDefaultVariableParams, DeleteDefaultVariablesResponse,
  UpdateDefaultVariablesParams,
  UpdateDefaultVariablesRequest,
  UpdateDefaultVariablesResponse,
} from '../types';

export class DefaultVariableService {
  static async createDefaultVariables(data: CreateDefaultVariablesRequest):
      Promise<AxiosResponse<CreateDefaultVariablesResponse>> {
    return api.post<CreateDefaultVariablesResponse>(ROUTES.DEFAULT_VARIABLES, data);
  }

  // eslint-disable-next-line max-len
  static async updateDefaultVariables(params: UpdateDefaultVariablesParams, data: UpdateDefaultVariablesRequest):
      Promise<AxiosResponse<UpdateDefaultVariablesResponse>> {
    return api.put<UpdateDefaultVariablesResponse>(`${ROUTES.DEFAULT_VARIABLES}/${params.conversation}/${params.name}`, data);
  }

  static async fetchDefaultVariables(conversation: string): Promise<AxiosResponse<[]>> {
    return api.get(`${ROUTES.DEFAULT_VARIABLES}/${conversation}`);
  }

  static async deleteDefaultVariable(params: DeleteDefaultVariableParams):
      Promise<AxiosResponse<DeleteDefaultVariablesResponse>> {
    return api.delete(`${ROUTES.DEFAULT_VARIABLES}/${params.conversation}/${params.name}`);
  }
}
