import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RegisterRequest } from '../../types';
import {
  CustomTextField, CustomTypography, CustomButton, CustomPaper,
} from '../../components';
import { validationSchema } from './rules';
import { useStore } from '../../hooks';
import { ROUTES } from '../../constants';

const DEFAULT_VALUES = {
  email: '',
  emailConfirmation: '',
  password: '',
  passwordConfirmation: '',
};

type FormInputsTypes = {
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
};

export const RegisterPage: React.FC = observer(() => {
  const { authStore: { register }, isSendingEmail } = useStore();
  const navigate = useNavigate();
  const { handleSubmit, reset, control } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });
  const handleRegister = useCallback(async (data: RegisterRequest): Promise<void> => {
    const isSuccessful = await register(data);
    if (isSuccessful) {
      reset();
      navigate(ROUTES.AUTH.VERIFY_EMAIL);
    }
  }, []);
  return (
    <CustomPaper>
      <CustomTypography text="Registration form" />
      <CustomTextField name="email" control={control} label="Email" />
      <CustomTextField name="emailConfirmation" control={control} label="Email Confirmation" />
      <CustomTextField name="password" control={control} label="Password" type="password" />
      <CustomTextField
        name="passwordConfirmation"
        control={control}
        label="Password Confirmation"
        type="password"
      />
      {isSendingEmail ? <h4 style={{ marginTop: '1rem' }}>New user registration...</h4> : <CustomButton onClick={handleSubmit(handleRegister)} title="Register" />}
    </CustomPaper>
  );
});
