import React from 'react';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';

type colors = 'primary' | 'secondary';

type CustomNavLinkProps = {
  to: string;
  title: string;
  color: colors;
};

const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
  margin: '1.5rem',
});

export const CustomNavLink: React.FC<CustomNavLinkProps> = ({ to, title, color }) => (
  <StyledNavLink to={to}>
    <Button size="large" variant="contained" color={color}>
      {title}
    </Button>
  </StyledNavLink>
);
