import React from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';
import { BREAKPOINTS } from '../../constants';

const Styled = styled(Paper)({
  margin: '3rem 12rem',
  padding: '4rem 12rem',
  width: '100%',
  [BREAKPOINTS.BIG]: {
    margin: '3rem 10rem',
    padding: '4rem 10rem',
  },
  [BREAKPOINTS.MEDIUM]: {
    margin: '3rem',
    padding: '4rem 7rem',
  },
  [BREAKPOINTS.SMALL]: {
    margin: '3rem 1rem',
    padding: '4rem 4rem',
  },
});

export const CustomPaper: React.FC<React.ReactNode> = ({ children }) => (
  <Styled elevation={6}>
    {children}
  </Styled>
);
