import React from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { ROUTES } from '../constants';
import { CustomTypography, CustomNavLink } from '../components';
import { useStore } from '../hooks';

const Div = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10rem',
});

export const HomePage: React.FC = observer(() => {
  const { authStore: { isAuthorized } } = useStore();
  return (
    isAuthorized
      ? (
        <Div>
          <CustomNavLink to={ROUTES.VARIABLES} title="User Variables" color="primary" />
          <CustomNavLink to={ROUTES.DEFAULT_VARIABLES} title="Default Variables" color="secondary" />
        </Div>
      ) : (
        <div style={{ paddingTop: '10rem', width: '100%' }}>
          <CustomTypography text="Please login or register to use the panel" />
          <Box textAlign="center">
            <CustomNavLink to={ROUTES.AUTH.LOGIN} title="Login" color="primary" />
            <CustomNavLink to={ROUTES.AUTH.REGISTER} title="Register" color="secondary" />
          </Box>
        </div>
      )
  );
});
