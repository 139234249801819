import React from 'react';
import { Button } from '@material-ui/core';
import { styled } from '@mui/system';
import { useStyles } from '../../hooks';

type CustomButtonProps = { title: string; onClick: () => void };

const Wrapper = styled('div')({
  marginTop: '1rem',
  marginRight: '1rem',
});

export const CustomButton: React.FC<CustomButtonProps> = ({ title, onClick }) => {
  const styles = useStyles();
  return (
    <Wrapper>
      <Button className={styles.noTransform} onClick={onClick} variant="contained">
        {title}
      </Button>
    </Wrapper>
  );
};
