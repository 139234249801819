import React, { useState } from 'react';
import { Delete, Edit, Save } from '@material-ui/icons';
import { Stack, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '../../../hooks';
import { EditableTableCell } from '../edit';
import { DefaultVariable } from '../../../types';
import { validationSchema } from './rules';

type Props = {
    defaultVariable: DefaultVariable
}

type FormInputsTypes = {
    value: string;
};

export const DefaultVariableTableRow: React.FC<Props> = ({ defaultVariable }) => {
  const DEFAULT_VALUES = {
    value: defaultVariable.value,
  };
  const [isEdit, setIsEdit] = useState(false);
  const { defaultVariableStore: { deleteDefaultVariable, updateDefaultVariable } } = useStore();
  const { handleSubmit, control } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const handleEdit = async (): Promise<void> => {
    setIsEdit(true);
  };

  const handleUpdate = async (data: FormInputsTypes): Promise<void> => {
    const response = await updateDefaultVariable(
      { conversation: defaultVariable.conversation, name: defaultVariable.name },
      data,
    );
    if (response) {
      setIsEdit(false);
    }
  };

  const handleDelete = async (): Promise<void> => {
    await deleteDefaultVariable(
      { conversation: defaultVariable.conversation, name: defaultVariable.name },
    );
  };
  return (
    <TableRow>
      <TableCell width="40%">{defaultVariable.name}</TableCell>
      <EditableTableCell
        value={defaultVariable.value}
        isEdit={isEdit}
        control={control}
      />
      <TableCell width="20%">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          { isEdit
            ? <Button onClick={handleSubmit(handleUpdate)}><Save /></Button>
            : <Button onClick={handleEdit}><Edit /></Button>}
          <Button onClick={handleDelete}>
            <Delete color="error" />
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
