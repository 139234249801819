import React, { useState } from 'react';
import { Delete, Edit, Save } from '@material-ui/icons';
import { Stack, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '../../../hooks';
import { EditableTableCell } from '../edit';
import { Variable } from '../../../types';
import { validationSchema } from './rules';

type Props = {
    variable: Variable
}

type FormInputsTypes = {
    value: string;
};

export const UserVariableTableRow: React.FC<Props> = ({ variable }) => {
  const DEFAULT_VALUES = {
    value: variable.value,
  };
  const [isEdit, setIsEdit] = useState(false);
  const { variableStore: { deleteVariable, updateVariable } } = useStore();
  const { handleSubmit, control } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });

  const handleEdit = async (): Promise<void> => {
    setIsEdit(true);
  };

  const handleUpdate = async (data: FormInputsTypes): Promise<void> => {
    const response = await updateVariable(
      { user: variable.user, conversation: variable.conversation, name: variable.name },
      data,
    );
    if (response) {
      setIsEdit(false);
    }
  };

  const handleDelete = async (): Promise<void> => {
    await deleteVariable(
      { user: variable.user, conversation: variable.conversation, name: variable.name },
    );
  };
  return (
    <TableRow>
      <TableCell width="30%">{variable.conversation}</TableCell>
      <TableCell width="30%">{variable.name}</TableCell>
      <EditableTableCell
        value={variable.value}
        isEdit={isEdit}
        control={control}
      />
      <TableCell width="10%">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          { isEdit
            ? <Button onClick={handleSubmit(handleUpdate)}><Save /></Button>
            : <Button onClick={handleEdit}><Edit /></Button>}
          <Button onClick={handleDelete}>
            <Delete color="error" />
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
