import * as Yup from 'yup';
import { stringValidation } from '../../helpers';

export const createDefaultVariableValidationSchema = Yup.object().shape({
  conversation: stringValidation('Conversation'),
  name: stringValidation('Name'),
  value: stringValidation('Value'),
});

export const getDefaultVariableValidationSchema = Yup.object().shape({
  conversation: stringValidation('Conversation'),
});
