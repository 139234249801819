import { makeAutoObservable } from 'mobx';
import { AuthStore, VariableStore, DefaultVariableStore } from './stores';
import { snackbarType } from '../types';
import { SNACKBAR } from '../constants';

class RootStore {
  isLoading = true;

  isSendingEmail = false;

  isSnackBarOpen = false;

  snackBarType: snackbarType = SNACKBAR.SEVERITY.SUCCESS;

  snackBaryTypeDefault: snackbarType = SNACKBAR.SEVERITY.SUCCESS;

  snackBarMessage = '';

  public authStore: AuthStore;

  public variableStore: VariableStore;

  public defaultVariableStore: DefaultVariableStore;

  constructor() {
    makeAutoObservable(this);
    this.authStore = new AuthStore(this);
    this.variableStore = new VariableStore(this);
    this.defaultVariableStore = new DefaultVariableStore(this);
  }

  setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setIsSendingEmail = (value: boolean): void => {
    this.isSendingEmail = value;
  };

  setSnackBar = ({ message = '', severity = this.snackBaryTypeDefault, isOpen = true }): void => {
    this.isSnackBarOpen = isOpen;
    this.snackBarType = severity;
    this.snackBarMessage = message;
  };
}

const rootStore = new RootStore();
export { rootStore, RootStore };
