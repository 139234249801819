import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import { ROUTES } from '../../constants';

export const AuthGuard: React.FC = observer(() => {
  const { authStore: { isAuthorized }, isLoading } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthorized) {
      navigate(ROUTES.ROOT);
    }
  }, [isAuthorized, isLoading, navigate]);
  return !isLoading && isAuthorized ? <Outlet /> : <h3>Authorizing...</h3>;
});
