import React from 'react';
import { Control } from 'react-hook-form';
import { TableCell } from '@mui/material';
import { CustomTextField } from '../../form';

type Props = {
    value: string
    isEdit: boolean
    control: Control<any>
}

export const EditableTableCell: React.FC<Props> = ({
  value, isEdit, control,
}) => (
  <TableCell width="30%">{isEdit ? <CustomTextField name="value" control={control} label="Value" /> : value}</TableCell>
);
