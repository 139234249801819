import { makeAutoObservable } from 'mobx';
import { RegisterRequest, UpdatePasswordRequest, User } from '../../types';
import { AuthService } from '../../services';
import { LOCAL_STORAGE, SNACKBAR } from '../../constants';
import { RootStore } from '../RootStore';

export class AuthStore {
  isAuthorized = false;

  user = {} as User;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setIsAuthorized = (value: boolean): void => {
    this.isAuthorized = value;
  };

  setUser = (value: User): void => {
    this.user = value;
  };

  setUserEmail = (email: string): void => {
    this.user.email = email;
  };

  login = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await AuthService.login({ email, password });
      localStorage.setItem(LOCAL_STORAGE.TOKEN, response.data.jwt);
      this.setUserEmail(email);
      this.setIsAuthorized(true);
      this.rootStore.setSnackBar({ message: 'Welcome to the admin panel!' });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
    return false;
  };

  register = async (data: RegisterRequest): Promise<boolean> => {
    this.rootStore.setIsSendingEmail(true);
    try {
      const response = await AuthService.register(data);
      this.rootStore.setSnackBar({ message: `${response.data.info}` });
      this.rootStore.setIsSendingEmail(false);
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
    this.rootStore.setIsSendingEmail(false);
    return false;
  };

  logout = (): void => {
    localStorage.removeItem('token');
    this.setIsAuthorized(false);
  };

  authorize = async (): Promise<null> => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await AuthService.authorize();
        this.setUser(response.data);
        this.setIsAuthorized(true);
        this.rootStore.setIsLoading(false);
      } catch (err) {
        this.rootStore.setSnackBar(
          { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
        );
      }
    }
    this.rootStore.setIsLoading(false);
    return null;
  };

  verifyEmail = async (email: string, verificationCode: string): Promise<boolean> => {
    try {
      const response = await AuthService.verifyEmail({ email, verificationCode });
      this.rootStore.setSnackBar({ message: response.data.info });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
    return false;
  };

  sendRecoveryCode = async (email: string): Promise<boolean> => {
    this.rootStore.setIsSendingEmail(true);
    try {
      const response = await AuthService.sendRecoveryCode({ email });
      this.rootStore.setSnackBar({ message: response.data.info });
      this.rootStore.setIsSendingEmail(false);
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
    this.rootStore.setIsSendingEmail(false);
    return false;
  };

  updatePassword = async (data: UpdatePasswordRequest): Promise<boolean> => {
    try {
      const response = await AuthService.updatePassword(data);
      this.rootStore.setSnackBar({ message: response.data.info });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
    return false;
  };
}
