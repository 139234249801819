import * as Yup from 'yup';
import {
  emailValidation,
  passwordConfirmationValidation,
  passwordValidation,
  verificationCodeValidation,
} from '../../../helpers';

export const validationSchema = Yup.object().shape({
  email: emailValidation(),
  verificationCode: verificationCodeValidation(),
  password: passwordValidation(),
  passwordConfirmation: passwordConfirmationValidation(),
});
