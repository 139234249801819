import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@mui/system';

const Styled = styled(Typography)({
  textAlign: 'center',
  paddingBottom: '1rem',
});

export const CustomTypography = ({ text }: { text: string }): JSX.Element => <Styled variant="h5">{text}</Styled>;
