import * as Yup from 'yup';
import { RULES } from '../../constants';

export const emailValidation = (): Yup.StringSchema => Yup.string().required('Email is required').email('Email is invalid');

export const stringValidation = (name: string): Yup.StringSchema => Yup.string().required(`${name} is required`);

export const emailConfirmationValidation = (): Yup.StringSchema => Yup.string()
  .required('Email Confirmation is required')
  .email('Email Confirmation is invalid')
  .oneOf([Yup.ref('email'), null], 'Email Confirmation does not match');

export const verificationCodeValidation = (): Yup.StringSchema => Yup.string()
  .required('Verification Code is required')
  .matches(RULES.CODE_VERIFICATION, 'Verification Code must be 5 digits number');

export const passwordValidation = (): Yup.StringSchema => Yup.string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters')
  .max(40, 'Password must not exceed 40 characters');

export const passwordConfirmationValidation = (): Yup.StringSchema => Yup.string()
  .required('Password Confirmation is required')
  .min(8, 'Password Confirmation must be at least 8 characters')
  .max(40, 'Password Confirmation must not exceed 40 characters')
  .oneOf([Yup.ref('password'), null], 'Password Confirmation does not match');
