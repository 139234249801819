import * as Yup from 'yup';
import { stringValidation } from '../../helpers';

export const getUserVariableValidationSchema = Yup.object().shape({
  userId: stringValidation('User ID'),
});

export const createUserVariableValidationSchema = Yup.object().shape({
  user: stringValidation('User'),
  conversation: stringValidation('Conversation'),
  name: stringValidation('Name'),
  value: stringValidation('Value'),
});
