import { AxiosResponse } from 'axios';
import { api } from '../http';
import {
  LoginResponse, InfoResponse, LoginRequest,
  RegisterRequest, User, VerifyEmailRequest,
  SendRecoveryCodeRequest,
  UpdatePasswordRequest,
} from '../types';
import { ROUTES } from '../constants';

export class AuthService {
  static async login(data: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return api.post<LoginResponse>(ROUTES.AUTH.LOGIN, data);
  }

  static async register(data: RegisterRequest): Promise<AxiosResponse<InfoResponse>> {
    return api.post<InfoResponse>(ROUTES.AUTH.REGISTER, data);
  }

  static async authorize(): Promise<AxiosResponse<User>> {
    return api.get<User>(ROUTES.USER.CURRENT);
  }

  static async verifyEmail(data: VerifyEmailRequest): Promise<AxiosResponse<InfoResponse>> {
    return api.post<InfoResponse>(
      ROUTES.AUTH.VERIFY_EMAIL,
      { email: data.email, verificationCode: +data.verificationCode },
    );
  }

  static async sendRecoveryCode(data: SendRecoveryCodeRequest)
      : Promise<AxiosResponse<InfoResponse>> {
    return api.post<InfoResponse>(
      ROUTES.AUTH.RECOVER_PASSWORD,
      data,
    );
  }

  static async updatePassword(data: UpdatePasswordRequest)
      : Promise<AxiosResponse<InfoResponse>> {
    return api.post<InfoResponse>(
      ROUTES.AUTH.UPDATE_PASSWORD,
      { ...data, verificationCode: +data.verificationCode },
    );
  }
}
