import React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/system';
import { Header, Footer } from '../../components';
import { useStore } from '../../hooks';
import { BREAKPOINTS } from '../../constants';

const Main = styled('main')({
  display: 'flex',
  margin: '3rem 20rem',
  [BREAKPOINTS.BIG]: {
    margin: '3rem 10rem',
  },
  [BREAKPOINTS.MEDIUM]: {
    margin: '3rem',
  },
  [BREAKPOINTS.SMALL]: {
    margin: '3rem 1rem',
  },
});

export const Layout: React.FC = observer(() => {
  const { isLoading } = useStore();

  return isLoading ? (
    <h3>Loading...</h3>
  ) : (
    <>
      <Header />

      <Main>
        <Outlet />
      </Main>

      <Footer />
    </>
  );
});
