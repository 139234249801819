import { makeAutoObservable } from 'mobx';
import { RootStore } from '../RootStore';
import {
  DeleteVariableParams, UpdateVariableParams, UpdateVariableRequest, Variable,
} from '../../types';
import { VariableService } from '../../services';
import { SNACKBAR } from '../../constants';

export class VariableStore {
  userVariables: Array<Variable> = [];

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setUserVariables = (variables: Array<Variable>): void => {
    this.userVariables = variables;
  };

  getUserVariables = async (userId: string): Promise<void> => {
    try {
      const response = await VariableService.fetchUserVariables(userId);
      if (!response.data.length) {
        this.rootStore.setSnackBar({ severity: SNACKBAR.SEVERITY.ERROR, message: `User with id: '${userId}' does not exist` });
      }
      this.setUserVariables(response.data);
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
    }
  };

  deleteVariable = async (params: DeleteVariableParams): Promise<boolean> => {
    try {
      await VariableService.deleteVariable(params);
      await this.getUserVariables(params.user);
      this.rootStore.setSnackBar({ message: 'Variable was successfully deleted' });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
      return false;
    }
  };

  updateVariable = async (params: UpdateVariableParams, data: UpdateVariableRequest):
      Promise<boolean> => {
    try {
      await VariableService.updateVariable(params, data);
      await this.getUserVariables(params.user);
      this.rootStore.setSnackBar({ message: 'Variable was successfully updated' });
      return true;
    } catch (err) {
      this.rootStore.setSnackBar(
        { severity: SNACKBAR.SEVERITY.ERROR, message: err.response?.data?.message },
      );
      return false;
    }
  };
}
