import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import {
  HomePage, LoginPage, RecoverPasswordPage, RegisterPage,
  VerifyEmailPage, UpdatePasswordPage, VariablesPage, DefaultVariablesPage,
  CreateDefaultVariablesPage, UpdateDefaultVariablesPage, CreateUserVariablesPage,
  UpdateUserVariablesPage,
} from '../pages';
import { Layout } from '../container';
import { AuthGuard } from '../components';

export const Router: React.FC = () => useRoutes([
  {
    path: ROUTES.ROOT,
    element: <Layout />,
    children: [
      {
        path: ROUTES.ROOT,
        element: <HomePage />,
      },
      {
        path: ROUTES.AUTH.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTES.AUTH.REGISTER,
        element: <RegisterPage />,
      },
      {
        path: ROUTES.AUTH.VERIFY_EMAIL,
        element: <VerifyEmailPage />,
      },
      {
        path: ROUTES.AUTH.RECOVER_PASSWORD,
        element: <RecoverPasswordPage />,
      },
      {
        path: ROUTES.AUTH.UPDATE_PASSWORD,
        element: <UpdatePasswordPage />,
      },
      {
        path: ROUTES.ROOT,
        element: <AuthGuard />,
        children: [
          {
            path: ROUTES.VARIABLES,
            element: <VariablesPage />,
          },
          {
            path: ROUTES.CREATE_USER_VARIABLES,
            element: <CreateUserVariablesPage />,
          },
          {
            path: ROUTES.UPDATE_USER_VARIABLES,
            element: <UpdateUserVariablesPage />,
          },
          {
            path: ROUTES.DEFAULT_VARIABLES,
            element: <DefaultVariablesPage />,
          },
          {
            path: ROUTES.CREATE_DEFAULT_VARIABLES,
            element: <CreateDefaultVariablesPage />,
          },
          {
            path: ROUTES.UPDATE_DEFAULT_VARIABLES,
            element: <UpdateDefaultVariablesPage />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);
