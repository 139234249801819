import React, { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button } from '@material-ui/core';
import { styled } from '@mui/system';
import { ROUTES } from '../../constants';
import { CustomButton } from '../form';
import { useStore, useStyles } from '../../hooks';

const Styled = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Header: React.FC = observer(() => {
  const styles = useStyles();
  const { authStore: { isAuthorized, user, logout } } = useStore();
  const navigate = useNavigate();

  const handleLogout = useCallback((): void => {
    logout();
    navigate(ROUTES.ROOT);
  }, []);

  return (
    <Styled>
      <NavLink className={styles.noDecoration} to={ROUTES.ROOT}>
        <Button size="large">Home</Button>
      </NavLink>
      {isAuthorized && (
      <div style={{ display: 'flex' }}>
        <h3 style={{ margin: '1rem' }}>{user.email}</h3>
        <CustomButton onClick={handleLogout} title="Logout" />
      </div>
      )}
    </Styled>
  );
});
