import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BREAKPOINTS, COLORS } from '../../constants';
import { useStore } from '../../hooks';
import {
  CustomButton, UserVariableTableRow, CustomTextField, CustomTypography,
} from '../../components';
import { getUserVariableValidationSchema } from './rules';

const DEFAULT_VALUES = {
  userId: '',
};

type FormInputsTypes = {
  userId: string;
};

const Div = styled('div')({
  padding: '0 20rem',
  [BREAKPOINTS.BIG]: {
    padding: '0 14rem',
  },
  [BREAKPOINTS.MEDIUM]: {
    padding: '0 9rem',
  },
  [BREAKPOINTS.SMALL]: {
    padding: '0 5rem',
  },
});

export const UpdateUserVariablesPage: React.FC = observer(() => {
  const { handleSubmit, control } = useForm<FormInputsTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(getUserVariableValidationSchema),
  });
  const { variableStore: { userVariables, getUserVariables, setUserVariables } } = useStore();

  const fetchUserVariables = async (data: FormInputsTypes): Promise<void> => {
    await getUserVariables(data.userId);
  };

  useEffect(() => { setUserVariables([]); }, []);

  return (
    <div style={{ width: '100%' }}>
      <Div>
        <CustomTypography text="Get user variables" />
        <CustomTextField name="userId" control={control} label="User ID" />
        <CustomButton onClick={handleSubmit(fetchUserVariables)} title="Get variables" />
      </Div>

      <TableContainer component={Paper} style={{ marginTop: '2rem', width: '100%' }}>
        {userVariables.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: COLORS.TABLE_HEAD }}>
              <TableRow>
                <TableCell width="30%"><strong>CONVERSATION</strong></TableCell>
                <TableCell width="30%"><strong>NAME</strong></TableCell>
                <TableCell width="30%"><strong>VALUE</strong></TableCell>
                <TableCell width="10%" align="center"><strong>ACTIONS</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line max-len */}
              {userVariables.map((value) => <UserVariableTableRow variable={value} key={value.id} />)}
            </TableBody>

          </Table>
        ) : (
          <h4 style={{ padding: '1rem', textAlign: 'center', color: 'gray' }}>
            Enter user ID and press &apos;Get variables&apos; button to see the user variables
          </h4>
        )}
      </TableContainer>
    </div>

  );
});
