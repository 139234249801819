import React from 'react';
import { styled } from '@mui/system';
import { COLORS } from '../../constants';

const year = new Date().getFullYear();

const StyledFooter = styled('footer')({
  position: 'fixed',
  bottom: 0,
  backgroundColor: COLORS.SECONDARY,
  color: 'white',
  width: '100%',
  textAlign: 'center',
  padding: '0.5rem 0',
});

export const Footer: React.FC = () => (
  <StyledFooter>
    &copy; Pandatron
    {' '}
    {year}
  </StyledFooter>
);
