import React, { useEffect } from 'react';
import { StoreContext } from './StoreContext';
import { rootStore } from './RootStore';
import { useStore } from '../hooks';

type StoreProviderProps = {
  children: React.ReactNode;
};

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const { authStore: { authorize } } = useStore();

  useEffect(() => {
    const fetchStoreData = async (): Promise<void> => {
      await authorize();
    };
    fetchStoreData();
  }, [authorize]);

  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};
