import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from '../router';
import { StoreProvider } from '../store';
import { GlobalStyle, SnackBar } from '../components';

export const App: React.FC = () => (
  <BrowserRouter>
    <StoreProvider>
      <GlobalStyle />
      <SnackBar />
      <Router />
    </StoreProvider>
  </BrowserRouter>
);
