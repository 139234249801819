import React from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, Snackbar } from '@mui/material';
import { useStore } from '../../hooks';
import { SNACKBAR } from '../../constants';

export const SnackBar: React.FC = observer(() => {
  const {
    snackBarMessage, isSnackBarOpen, setSnackBar, snackBarType,
  } = useStore();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === SNACKBAR.CLICKAWAY) {
      return;
    }
    setSnackBar({ isOpen: false });
  };
  return (
    <Snackbar open={isSnackBarOpen} autoHideDuration={SNACKBAR.HIDE_DURATION} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        elevation={SNACKBAR.ELEVATION}
        variant={SNACKBAR.VARIANT}
        color={snackBarType}
      >
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
});
