export const ROUTES = {
  ROOT: '/',
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    VERIFY_EMAIL: '/auth/verify',
    RECOVER_PASSWORD: '/auth/send-password-recovery-code',
    UPDATE_PASSWORD: '/auth/update-password',
  },
  VARIABLES: '/variables',
  CREATE_USER_VARIABLES: '/variables/create',
  UPDATE_USER_VARIABLES: '/variables/update',
  DEFAULT_VARIABLES: '/default-variables',
  CREATE_DEFAULT_VARIABLES: '/default-variables/create',
  UPDATE_DEFAULT_VARIABLES: '/default-variables/update',
  USER: {
    CURRENT: '/users/current',
  },
};
