import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInputProps } from '../../types';

export const CustomTextField: React.FC<FormInputProps> = ({
  name, control, label, type = 'text',
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
      <TextField
        autoComplete="new-password"
        size="small"
        type={type}
        error={!!error}
        helperText={error?.message}
        onChange={onChange}
        value={value}
        fullWidth
        label={label}
        variant="outlined"
        style={{
          marginTop: '0.4em',
          marginBottom: '0.4em',
        }}
      />
    )}
  />
);
